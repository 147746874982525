import React from "react";
import LogoWhite from "./assets/logos/logo-white.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div>
        <img alt="volum logo with a top hat" src={LogoWhite} />
        <p>Club</p>
      </div>
    </div>
  );
}

export default App;
